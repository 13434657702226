import React, { Fragment, Suspense, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Slide, ToastContainer, toast } from 'react-toastify';
import ProtectedNode from './ProtectedNode';
import PageLoader from '../Loader/PageLoader';
import { resetAuthData } from '../../store/slices/authUser/authUserSlice';
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/css/tabler.css';
import '../../assets/css/tabler-payments.css';
import '../../assets/css/flags.css';
import '../../assets/css/custom.css';
import {
  hideLoginModal,
  showLoginModal,
} from '../../store/slices/authUser/loginModalSlice';
import ScrollToTop from '../Interface/ScrollToTop';
import * as Location from '../../api/GeoLocationAPI';
import {
  resetIpGeolocation,
  setIpGeolocation,
} from '../../store/slices/geoLocation/ipGeoLocationSlice';
import { store } from '../../store/store'; // Adjust the path according to your file structure

const Layout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.rcsl.authUserReducer);
  const ipGeoLocationSelector = useSelector(
    (state) => state.rcsl.ipGeolocationReducer,
  );
  // const token = new URLSearchParams(window.location.search).get("token");
  useEffect(() => {
    dispatch(hideLoginModal());
    const fetchGeolocation = async () => {
      try {
        // Call the geolocation API
        const { data } = await Location.getIpGeoLocation();
        // console.log("🚀 ~ fetchGeolocation ~ data:", data)
        if (data) {
          const city = data.city || '';
          const province = data.state_prov || '';
          const country = data.country_code2 || '';
          const ip = data.ip || '';
          // Dispatch the geolocation data to Redux
          if (['CA', 'US'].includes(country)) {
            dispatch(setIpGeolocation({ city, province, country, ip }));
          } else {
            throw new Error('Set default country');
          }
        }
      } catch (error) {
        dispatch(resetIpGeolocation);
      }
    };
    if (ipGeoLocationSelector.ip == '') {
      fetchGeolocation();
    }
  }, []);
  axios.interceptors.request.use(
    (req) => {
      req.baseURL = process.env.REACT_APP_API_URL;
      const state = store.getState(); // Get the latest state
      const ipGeoLocationSelector = state.rcsl.ipGeolocationReducer;
      if (authSelector?.token) {
        req.headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authSelector.token}`,
          ...req.headers,
        };
      }
      req.headers['X-Country'] = ipGeoLocationSelector.country;
      req.headers['X-Province'] = ipGeoLocationSelector.province;
      req.headers['X-City'] = ipGeoLocationSelector.city;
      return req;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // catches if the session ended!
      if (error.response.status === 401) {
        if (error.response.data.message === 'Token expired!') {
          const toastId = 'toast-session-expired';
          if (!toast.isActive(toastId)) {
            dispatch(resetAuthData());
            dispatch(
              showLoginModal({
                login: true,
                signup: false,
              }),
            );
          }
          dispatch(resetAuthData());
          // navigate(`/${token ? `?token=${token}` : ``}`);
          return Promise.reject(error);
        } else if (error.response.data.message === 'Unauthorized') {
          dispatch(
            showLoginModal({
              login: true,
              signup: false,
            }),
          );
          return Promise.reject(error);
        }
      } else {
        return Promise.reject(error);
      }
    },
  );
  return (
    <Fragment>
      <Suspense fallback={<PageLoader />}>
        <ProtectedNode authSelector={authSelector} />
      </Suspense>
      <ToastContainer
        pauseOnFocusLoss
        draggable={false}
        hideProgressBar={true}
        theme="colored"
      />
      <ScrollToTop />
    </Fragment>
  );
};

export default Layout;
