import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';

const VerifyToken = lazy(() => import('../pages/Verifytoken/VerifyToken'));
const ResetPassword = lazy(
  () => import('../pages/Authentication/ResetPassword'),
);
const TenancyApplicationForm = lazy(
  () => import('../pages/TenancyApplication.jsx/TenancyApplicationForm'),
);
const TenancyApplicationForm1 = lazy(
  () => import('../pages/TenancyApplication.jsx/TenancyApplicationForm1'),
);
const Home = lazy(() => import('../pages/Home/Home'));
const FeaturedLease = lazy(() => import('../pages/Featured/FeaturedLease'));
const Area = lazy(() => import('../pages/Area/Area'));
const AddEditProperty = lazy(() => import('../pages/Property/AddEditProperty'));
const Units = lazy(() => import('../pages/Property/Units/Units'));
const Search = lazy(() => import('../pages/Search/Search'));
const MyAccount = lazy(() => import('../pages/MyAccount/MyAccount'));
const MyApplication = lazy(
  () => import('../pages/MyApplication/MyApplication'),
);
const OfferReview = lazy(
  () => import('../pages/MyAccount/OfferReview/OfferReview'),
);
const MyBooking = lazy(() => import('../pages/MyBooking/MyBooking'));
const MyOffers = lazy(() => import('../pages/MyOffers/MyOffers'));
const MyFavourites = lazy(() => import('../pages/MyFavourites/MyFavourites'));

const Billing = lazy(() => import('../pages/Billing/Billing'));
const ManagePayment = lazy(() => import('../pages/Billing/ManagePayment'));
const PlanExpired = lazy(() => import('../pages/Billing/PlanExpired'));

const PropertyDetails = lazy(
  () => import('../pages/Property/PropertyDetails/PropertyDetails'),
);

const Logout = lazy(() => import('../pages/Authentication/Logout'));
const ErrorPage = lazy(() => import('../pages/ErrorPage'));

const ApproveApplicationMobile = lazy(
  () =>
    import('../pages/MyAccount/OfferReview/Mobile/ApproveApplicationMobile'),
);
const ViewApplicationMobile = lazy(
  () => import('../pages/MyAccount/OfferReview/Mobile/ViewApplicationMobile'),
);

const Features = lazy(() => import('../pages/Features/Features'));
const Terms = lazy(() => import('../pages/CMS/Terms'));
const PrivacyPolicy = lazy(() => import('../pages/CMS/PrivacyPolicy'));
const commonRoute = [
  {
    path: '/features',
    element: <Features />,
  },
  {
    path: 'search',
    element: <Search />,
  },
  {
    path: 'property-details',
    element: <PropertyDetails />,
  },
  {
    path: 'featured/lease',
    element: <FeaturedLease />,
  },
  {
    path: 'area/:province',
    element: <Area />,
  },
  {
    path: 'application/form',
    element: <TenancyApplicationForm />,
  },
  {
    path: 'application/form1',
    element: <TenancyApplicationForm1 />,
  },
  {
    path: 'verify/:token',
    element: <VerifyToken />,
  },
  {
    path: '/reset-password/:token',
    element: <ResetPassword />,
  },
  {
    path: '/terms-and-conditions',
    element: <Terms />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
];
const unauthorizedRoutes = [
  {
    path: '/',
    children: [
      ...[
        {
          index: true,
          element: <Home />,
        },
        {
          path: 'logout',
          element: <Logout />,
        },
      ],
      ...commonRoute,
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" replace={true} />,
  },
];
const getProtectedRoutes = (auth) => {
  const protectedRoutes = [
    {
      path: '/',
      element: <ProtectedRoute redirectPath="/" auth={auth} />,
      children: [
        ...[
          {
            index: true,
            element: <Home />,
          },
          {
            path: 'add-property',
            element: <AddEditProperty />,
          },
          {
            path: 'add-units',
            element: <Units />,
          },
          {
            path: 'my-listings',
            element: <MyAccount />,
          },
          {
            path: 'my-booking',
            element: <MyBooking />,
          },
          {
            path: 'my-offers',
            element: <MyOffers />,
          },
          {
            path: 'my-applications',
            element: <MyApplication />,
          },
          {
            path: 'offer-review',
            element: <OfferReview />,
          },
          {
            path: 'favourites',
            element: <MyFavourites />,
          },
          {
            path: 'plan-details',
            element: <Billing />,
          },
          {
            path: 'manage-payment',
            element: <ManagePayment />,
          },
          {
            path: 'plan-expired',
            element: <PlanExpired />,
          },
          {
            path: 'logout',
            element: <Logout />,
          },
          {
            path: 'mobile/approve-application',
            element: <ApproveApplicationMobile />,
          },
          {
            path: 'mobile/view-application',
            element: <ViewApplicationMobile />,
          },
        ],
        ...commonRoute,
      ],
    },
    { path: '*', element: <ErrorPage /> },
  ];
  return protectedRoutes;
};
export const Routes = (auth) => {
  return auth ? getProtectedRoutes(auth) : unauthorizedRoutes;
};
