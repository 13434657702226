import React, { useEffect, useRef, useState } from 'react';
import { IconUser } from '@tabler/icons-react';
import { Badge, Dropdown, NavItem } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { ACCOUNT_DROPDOWN_ITEMS, ROLES } from '../../common/constants';
import MyProfile from '../../pages/MyProfile/MyProfile';
import ChangePassword from '../../pages/MyProfile/ChangePassword';
import Utils from '../../Utils';

const HeaderDropdown = ({ isMobile = false }) => {
  const authSelector = useSelector((state) => state.rcsl.authUserReducer);
  const navigate = useNavigate();
  // const userName = `${authSelector.first_name} ${authSelector.last_name ? authSelector.last_name[0] : ""}`;
  const userName =
    authSelector.role === ROLES.COMPANY
      ? authSelector.company_name
      : `${authSelector.first_name} ${authSelector.last_name ? authSelector.last_name[0] : ''}`;
  const [myProfileModal, setMyProfileModal] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const remainingDays = authSelector?.plan_end_date
    ? Utils.daysBetween(authSelector?.plan_end_date)
    : authSelector?.currentPlan?.end_date
      ? Utils.daysBetween(authSelector?.currentPlan?.end_date)
      : 0;
  const handleModal = (to) => {
    if (to === 'my-profile') {
      setMyProfileModal(true);
    } else if (to === 'change-password') {
      setChangePasswordModal(true);
    }
  };
  return (
    <NavItem as={Dropdown} className="cursor-pointer">
      <Dropdown.Toggle
        as={'a'}
        bsPrefix={`nav-link d-flex lh-1 text-reset p-0 text-dark dropdown-toggle ${!isMobile ? 'dropdown-toggle' : ''}`}
      >
        <span
          className={`avatar  bg-primary-lt rounded-circle ${!isMobile ? 'avatar-sm' : ''}`}
          style={{
            backgroundImage: authSelector.profile_image
              ? `url('${process.env.REACT_APP_UPLOAD_BASE_URL}${authSelector.profile_image}')`
              : 'none',
          }}
        >
          {authSelector.profile_image === '' && <IconUser className="icon" />}
        </span>
        <div className="d-none d-xl-block ps-2">
          <div>{userName}</div>
          <div className="mt-1 small text-muted">{authSelector.role}</div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        align="end"
        className="dropdown-menu-end dropdown-menu-arrow-rounded-3 dd-menu-profile"
      >
        <Dropdown.Item className="fs-3">
          <span
            onClick={() => {
              handleModal('my-profile');
            }}
            className={`avatar  bg-primary-lt rounded-circle ${!isMobile ? 'avatar-sm' : ''}`}
            style={{
              backgroundImage: authSelector.profile_image
                ? `url('${process.env.REACT_APP_UPLOAD_BASE_URL}${authSelector.profile_image}')`
                : 'none',
            }}
          >
            {authSelector.profile_image === '' && <IconUser className="icon" />}
          </span>
          <div className="ps-2 text-truncate" style={{ maxWidth: '200px' }}>
            <div
              className="fw-bold text-truncate"
              onClick={() => {
                handleModal('my-profile');
              }}
            >
              Hi, {`${authSelector.first_name} ${authSelector.last_name || ''}`}
            </div>
            {/* <span className="small text-primary">View Profile</span> */}
            {(authSelector.role === ROLES.COMPANY ||
              authSelector.role === ROLES.OWNER) &&
              authSelector?.is_active_plan && (
                <div className="nav-item d-none d-md-flex me-3">
                  <div className="btn-list">
                    <span
                      onClick={() => navigate('/plan-details')}
                      className={` h3 py-0 mb-0 ${remainingDays < 0 ? 'text-danger' : 'text-success'}`}
                    >
                      {remainingDays < 0
                        ? 'Plan Expired'
                        : `${remainingDays} Day Left`}{' '}
                    </span>
                  </div>
                </div>
              )}
          </div>
        </Dropdown.Item>
        <Dropdown.Divider className="m-2" />
        {ACCOUNT_DROPDOWN_ITEMS.map((item, idx) => {
          const { value, to } = item;
          if (
            to === '/manage-payment' &&
            authSelector.role !== ROLES.COMPANY &&
            authSelector.role !== ROLES.OWNER &&
            authSelector.role !== ROLES.TENANT &&
            authSelector.role !== ROLES.GUEST
          ) {
            return '';
          }
          if (
            to === '/plan-details' &&
            authSelector.role !== ROLES.COMPANY &&
            authSelector.role !== ROLES.OWNER
          ) {
            return '';
          }
          if (to === 'my-profile' || to === 'change-password') {
            return (
              <Dropdown.Item
                key={`dd-${idx}`}
                onClick={() => {
                  handleModal(to);
                }}
                className="fs-3"
              >
                {value}
              </Dropdown.Item>
            );
          }
          return (
            <Dropdown.Item
              key={`dd-${idx}`}
              as={NavLink}
              to={to}
              className="fs-3"
            >
              {value}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
      {myProfileModal && (
        <MyProfile {...{ myProfileModal, setMyProfileModal }} />
      )}
      {changePasswordModal && (
        <ChangePassword {...{ changePasswordModal, setChangePasswordModal }} />
      )}
    </NavItem>
  );
};

export default HeaderDropdown;
