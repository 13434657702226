export const ROLES = {
  SUPERADMIN: 'superadmin',
  COMPANY: 'company',
  MANAGER: 'manager',
  OWNER: 'owner',
  TENANT: 'tenant',
  VENDOR: 'vendor',
  GUEST: 'guest',
};
export const MIN_PRICE = [
  { label: '0', value: 0 },
  { label: '$10000', value: 10000 },
  { label: '$20000', value: 20000 },
  { label: '$30000', value: 30000 },
];
export const MAX_PRICE = [
  { label: '< $1000', value: 1000 },
  { label: '< $2000', value: 2000 },
  { label: '< $3000', value: 3000 },
  { label: '< $4000', value: 4000 },
  { label: '< $5000', value: 5000 },
  { label: '< $6000', value: 6000 },
  { label: '< $7000', value: 7000 },
  { label: '$7000+', value: 100000 },
];
export const BED_BATH_NUMBER = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
];
export const ACCOUNT_DROPDOWN_ITEMS = [
  {
    value: 'My Listings',
    to: 'my-listings',
  },
  {
    value: 'My Bookings',
    to: 'my-booking',
  },
  {
    value: 'My Offers',
    to: 'my-offers',
  },
  // {
  //     value: "My Applications",
  //     to: "my-applications",
  // },
  {
    value: 'Favourites',
    to: '/favourites',
  },
  {
    value: 'Plan Details',
    to: '/plan-details',
  },
  {
    value: 'Manage Payment',
    to: '/manage-payment',
  },
  {
    value: 'Logout',
    to: '/logout',
  },
];
export const BOOKING_STATUS = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
  CANCELLED: 'cancelled',
};
export const APPLICATION_STATUS = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  SUBMITTED: 'submitted',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  RAISED: 'raised',
  CANCELLED: 'cancelled',
  DECLINED: 'declined',
};
export const DEFAULT_CONFIRM_MODAL = {
  show: false,
  isLoading: false,
  title: 'Are you sure?',
  description:
    "Do you really want to remove this data? what you've done can't be undone.",
  actionBtnText: 'Delete',
  action: 'DELETE',
};
export const DEFAULT_FORM_CONFIRM_MODAL = {
  show: false,
  isLoading: false,
  title: 'Are you sure?',
  description: 'Do you really want to submit?',
  actionBtnText: 'Yes',
  action: 'YES',
};
export const CARD_TYPE = {
  FAVORITE: 'favorite',
};
export const ADD_PROPERTY_TEXT = 'Post a Property';
export const TEXT_CONSTANT = {
  TAX_TEXT: '+taxes and other charges',
  ADD_PROPERTY_BUTTON_TEXT: 'List your Property',
  LOGIN_BUTTON_TEXT: 'Login',
};
export const CONFIG = {
  LIMIT: 12,
};
export const CONTACT_EMAIL = 'info@look4lease.com';
export const CONTACT_PHONE = '+12048811800';
